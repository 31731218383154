import { Injectable, inject, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment.dev';
import { AgencyDetails, Instruments, ResourceData } from '@interfaces/resource.interface';
import { DataSourceLinks, SendEmail } from '@interfaces/dataSource.interface';
import { CreatePreferences, UserInfo } from '@interfaces/dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public userInfo = signal<UserInfo | null>(null);
  public showWelcomeBanner = signal<boolean>(true);
  public preferences = signal<CreatePreferences | null>(null);

  private readonly _http = inject( HttpClient );
  private readonly _endpoint = environment.apiURL;

  constructor() { }

  public setUserPreferences(preferences: any){
    this.preferences.set(preferences);
  }

  public getPreferencesFromUser(): CreatePreferences | null {
    return this.preferences();
  }

  public userInformation(user: any) {
    this.userInfo.set(user);
  }

  public getuserInformation(): UserInfo | null {
    return this.userInfo();
  }

  public updateWelcomeBanner(value: boolean) {
    this.showWelcomeBanner.set(value);
  }

  public createPreferences(preferencesData: CreatePreferences, idToken: string): Observable<any> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${idToken}`
    });
    return this._http.post<any>(`${this._endpoint}CreateUserPreferences`, preferencesData, { headers });
  }

  public getUserPreferences(email: string, idToken: string): Observable<string> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${idToken}`
    });
    return this._http.get<any>(`${this._endpoint}GetUserPreferences?email=${email}`, { headers });
  }

  public editUserPreferences(preferencesData: CreatePreferences, idToken: string): Observable<any> {
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${idToken}`
    });
    return this._http.post<any>(`${this._endpoint}EditUserPreferences`, preferencesData, { headers });
  }

  public getSectorData(startYear: number, endYear: number, countries: string, sectors: string): Observable<any> { 
      return this._http.get<any[]>(`${this._endpoint}GetYearSectorialData?startYear=${startYear}&endYear=${endYear}&countries=${countries}&sectors=${sectors}`);
  }
}
